import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AppsIcon from '@mui/icons-material/Apps';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import DirectionsIcon from '@mui/icons-material/Directions';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import SchoolIcon from '@mui/icons-material/School';
import React, {PropsWithChildren, useEffect, useState} from 'react';

import {
  CatalogIcon,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
} from '@backstage/core-components';
import {devToolsAdministerPermission} from '@backstage/plugin-devtools-common';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import BuildIcon from '@material-ui/icons/Build';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {SidebarLogo} from './Logo/SidebarLogo';
import {LocalStorage} from './util';

export const SynechronSidebar = ({ children }: PropsWithChildren<{}>) => {
  const [hasRendered, setHasRendered] = useState(false);
  const hasRemovedDefaultPinState =
    LocalStorage.getHasRemovedPinnedSettingsDefault();

  useEffect(() => {
    /*
      Hacky: To avoid recreating the <SidebarPage> component, which defaults to a pinned sidebar,
      we set the pin state to false. This is done directly through LocalStorage because the
      useSidebarPinState context is not available at this point in the render.
    */
    if (!hasRemovedDefaultPinState) {
      LocalStorage.setSidebarPinState(false);
      LocalStorage.setHasRemovedPinnedSettingsDefault();
    }

    setHasRendered(true);
  }, [hasRemovedDefaultPinState]);

  return hasRendered ? (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarGroup label="Home">
          <SidebarItem icon={HomeIcon} to="/homepage" text="Home" />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItem icon={CatalogIcon} to="components" text="Catalog"/>
          <SidebarItem icon={AppsIcon} to="systems" text="Systems"/>
          <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarItem
            icon={CreateComponentIcon}
            to="create"
            text="Create..."
          />
          <SidebarDivider />
          <SidebarScrollWrapper>
            <SidebarItem icon={DirectionsIcon} to="tech-radar" text="Skills Radar"/>
            <SidebarItem icon={SchoolIcon} to="docs/default/component/speed-engineering-guidelines" text="SPEED"/>
            <RequirePermission
                permission={devToolsAdministerPermission}
                errorPage={<></>}
            >
              <SidebarItem
                  icon={AccountBalanceIcon}
                  to="infrawallet"
                  text="FinOps"
              />
            </RequirePermission>
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <RequirePermission
            permission={devToolsAdministerPermission}
            errorPage={<></>}
          >
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
          </RequirePermission>
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  ) : null;
};
