import Box from '@mui/material/Box';
import React from 'react';

/* The CatalogTable component provides no way for us to customize the styling of the table,
 we can use tableOptions={{ toolbar: false }} to hide it, but this also hides the search bar. */

export const TableWithInjectedStyles = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Box
    sx={{
      div: {
        '&[class^="MuiToolbar-root"]': {
          py: 0,
          '& > h5, h2': {
            fontSize: 'larger',
            fontWeight: 'normal',
            margin: 0,
          },
        },
      },
    }}
  >
    {children}
  </Box>
);
