import type { FieldValidation } from '@rjsf/utils';
import { CustomGitlabPickerFormData, CustomGitlabPickerOptions } from './types';

export function parseRepoPickerUrl(
  url: string | undefined,
): CustomGitlabPickerFormData {
  let owner = '';
  let repo = '';

  try {
    if (url) {
      const parsed = new URL(`https://${url}`);
      owner = parsed.searchParams.get('owner') || '';
      repo = parsed.searchParams.get('repo') || '';
    }
  } catch {
    // Ignore invalid URLs
  }
  return { owner, repo };
}

/* Name restrictions based on https://docs.gitlab.com/ee/user/reserved_names.html */
const gitlabProjectNameRegex =
  /^[a-zA-Z0-9](?!.*[\W_]{2,})(?!.*[\W_]$)(?!.*\.git$)(?!.*\.atom$)[a-zA-Z0-9._-]*$/;
const gitlabReservedProjectNames = [
  '-',
  'badges',
  'blame',
  'blob',
  'builds',
  'commits',
  'create',
  'create_dir',
  'edit',
  'files',
  'find_file',
  'new',
  'preview',
  'raw',
  'refs',
  'tree',
  'update',
  'wikis',
];

export const getPickerErrors = ({
  repo,
}: Omit<CustomGitlabPickerFormData, 'owner'>): string[] => {
  const errors = [];

  if (repo) {
    if (!gitlabProjectNameRegex.test(repo)) {
      errors.push(
        'Project name must start and end with a letter or number and can contain only letters, numbers, underscores, periods, and hyphens.',
      );
    }

    if (gitlabReservedProjectNames.includes(repo)) {
      errors.push('Project name is reserved by GitLab.');
    }
  }

  return errors;
};

const isGroupInGroupList = (
  group: string,
  groupList: string[], // Can be excludedGroups or includedGroups
) =>
  groupList.some(groupListItem => {
    const groupListItemParts = groupListItem.split('/');
    const groupParts = group.split('/') ?? [];

    // >= if the group is identical to the groupListItem, or if it is a subgroup of the groupListItem
    // This is superior to using startsWith because it covers "group/subgroup", "group/subgroup-different".
    return (
      groupParts.length >= groupListItemParts.length &&
      groupListItemParts.every((part, index) => part === groupParts[index])
    );
  });

export const isGroupIncluded = (
  group: string,
  includedGroups: string[] | undefined,
  excludedGroups: string[] | undefined,
) => {
  let isIncluded = true;

  if (includedGroups?.length) {
    isIncluded = isGroupInGroupList(group, includedGroups);
  }

  if (excludedGroups?.length) {
    isIncluded = isIncluded && !isGroupInGroupList(group, excludedGroups);
  }

  return isIncluded;
};

export const groupResponseToDropdownItems = (
  response: { title?: string; id: string }[],
  uiOptions: CustomGitlabPickerOptions,
) =>
  response
    .filter(r =>
      isGroupIncluded(
        r.title!,
        uiOptions.includedGroups,
        uiOptions.excludedGroups,
      ),
    )
    .map(r => ({
      title: r.title!,
      id: r.id,
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

export const CustomGitlabPickerValidation = (
  value: string,
  validation: FieldValidation,
) => {
  const pickerOptions = parseRepoPickerUrl(value);

  const errors = getPickerErrors(pickerOptions);
  // To enable realtime validation in the component and avoid duplicating the list of errors after submission, we just add a single error after submission.
  if (!!errors.length)
    validation.addError(
      `Please correct the listed error${errors.length > 1 ? 's' : ''}.`,
    );
};
