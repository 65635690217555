import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { UnprocessedEntitiesContent } from '@backstage/plugin-catalog-unprocessed-entities';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { orgPlugin } from '@backstage/plugin-org';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { SynechronSidebar } from './components/sidebar';

import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { gitlabAuthApiRef } from '@backstage/core-plugin-api';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  ConfigContent,
  DevToolsLayout,
  DevToolsPage,
  InfoContent,
} from '@backstage/plugin-devtools';
import { VisitListener } from '@backstage/plugin-home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { UnifiedThemeProvider } from '@backstage/theme';
import {
  type CoderAppConfig,
  CoderProvider,
} from '@coder/backstage-plugin-coder';
import { HomePage } from './components/home/HomePage';
import * as plugins from './plugins';
import { defaultTheme } from './theme/defaultTheme';

import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import LightIcon from '@material-ui/icons/WbSunny';
import { genCatalogTableColumns } from './components/catalog/table/columns/genCatalogTableColumns';
import { genDocsTableColumns } from './components/catalog/table/columns/genDocsTableColumns';
import { TableWithInjectedStyles } from './components/catalog/table/TableWithInjectedStyles';
import { CustomGitlabPickerFieldExtension } from './components/scaffolder/fields/CustomGitlabRepoPicker';
import {InfraWalletPage} from "@electrolux-oss/plugin-infrawallet";

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          providers={[
            {
              id: 'gitlab-auth-provider',
              title: 'Gitlab',
              message: 'Login via Gitlab',
              apiRef: gitlabAuthApiRef,
            },
          ]}
          align="center"
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'Default Theme',
      title: 'Default Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={defaultTheme} children={children} />
      ),
    },
  ],
});

const appConfig: CoderAppConfig = {
  deployment: {
    accessUrl: 'https://cloud.synechron.com',
  },

  // Set the default template (and parameters) for
  // catalog items. Individual properties can be overridden
  // by a repo's catalog-info.yaml file
  workspaces: {
    // Defines the name of the Coder template when creating new workspaces from Backstage.
    defaultTemplateName: 'dev-container-general',

    // Either manual or auto. Controls whether the user is taken to the Coder Workspace creation GUI or
    // whether a Workspace is automatically created.
    defaultMode: 'auto',

    // This property defines which parameters in your Coder
    // workspace templates are used to store repository links
    repoUrlParamKeys: ['repo_url'],

    // Define additional Coder workspace parameter values
    // params: {
    //     repo: 'custom',
    //     region: 'uk south',
    // },
  },
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomePage />} />
    <Route
      path="/catalog"
      element={
        <TableWithInjectedStyles>
          <CatalogIndexPage
            initiallySelectedFilter="all"
            columns={genCatalogTableColumns}
          />
        </TableWithInjectedStyles>
      }
    />
      <Route path="/systems" element={<Navigate to="/catalog?filters%5Bkind%5D=system"/>}/>
      <Route path="/components" element={<Navigate to="/catalog?filters%5Bkind%5D=component"/>}/>

      <Route
          path="/infrawallet"
          element={
              <RequirePermission permission={devToolsAdministerPermission}>
                  <InfraWalletPage title="Cloud Costs" subTitle="InfraWallet"/>
              </RequirePermission>
          }
      />

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/docs"
      element={
        <TableWithInjectedStyles>
          <TechDocsIndexPage
            initialFilter="all"
            columns={genDocsTableColumns()}
          />
        </TableWithInjectedStyles>
      }
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <CustomGitlabPickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route
      path="/api-docs"
      element={
        <TableWithInjectedStyles>
          <ApiExplorerPage
            initiallySelectedFilter="all"
            // @ts-ignore - ApiExplorerPage DOES accept a function for columns just like the catalog page, but it's not typed
            columns={genCatalogTableColumns}
          />
        </TableWithInjectedStyles>
      }
    />
    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          title="Skills Radar"
          subtitle="We should aim for at least 'Novice' level when working on a project."
          pageTitle="Cloud & DevOps"
        />
      }
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/homepage" element={<HomePage />} />
    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      <DevToolsLayout>
        <DevToolsLayout.Route path="info" title="Info">
          <InfoContent />
        </DevToolsLayout.Route>
        <DevToolsLayout.Route path="config" title="Config">
          <ConfigContent />
        </DevToolsLayout.Route>
        <DevToolsLayout.Route
          path="unprocessed-entities"
          title="Unprocessed Entities"
        >
          <UnprocessedEntitiesContent />
        </DevToolsLayout.Route>
      </DevToolsLayout>
    </Route>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <CoderProvider appConfig={appConfig}>
        <VisitListener />
        <SynechronSidebar>{routes}</SynechronSidebar>
      </CoderProvider>
    </AppRouter>
  </>,
);
