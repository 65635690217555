import { useApi } from '@backstage/core-plugin-api';
import {
  scaffolderApiRef,
  useTemplateSecrets,
} from '@backstage/plugin-scaffolder-react';
import useAsync from 'react-use/esm/useAsync';
import { CustomGitlabPickerOptions } from '../../types';
import { groupResponseToDropdownItems } from '../../validation';
import { useSetScaffolderToken } from './useSetScaffolderToken';

export const useFetchGroups = (uiOptions: CustomGitlabPickerOptions) => {
  const scaffolderApi = useApi(scaffolderApiRef);
  const templateHasToken = useSetScaffolderToken(uiOptions); // Trigger login and/or token fetch

  const { secrets } = useTemplateSecrets();

  const {
    loading: groupsLoading,
    error: groupFetchError,
    value: availableGroups,
  } = useAsync(async () => {
    const token = secrets[uiOptions.requestUserCredentials.secretsKey];

    if (!token) {
      return null; // The useSetScaffolderToken is still loading, this hook will be called again when it's done
    }

    if (!scaffolderApi.autocomplete) {
      throw new Error(
        'Unable to fetch groups, the scaffolder API or Gitlab token is not available',
      );
    }

    try {
      // Fetch groups from Gitlab
      const { results } = await scaffolderApi.autocomplete({
        token,
        resource: 'groups',
        provider: 'gitlab',
        context: { host: 'gitlab.com' },
      });

      if (!results) {
        return [];
      }

      return groupResponseToDropdownItems(results, uiOptions);
    } catch (e) {
      throw new Error(`Failed to fetch groups: ${e.message}`);
    }
  }, [secrets[uiOptions.requestUserCredentials.secretsKey]]);

  return {
    groupsLoading: templateHasToken.loading || groupsLoading,
    availableGroups: templateHasToken.value ? availableGroups : null,
    groupFetchError: templateHasToken.error || groupFetchError,
  };
};
