import { TableColumn } from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import { EntityListContextProps } from '@backstage/plugin-catalog-react';

export const genCatalogTableColumns = (
  ctx: EntityListContextProps,
): TableColumn<CatalogTableRow>[] => {
  const defaultColumns = CatalogTable.defaultColumnsFunc(ctx).map(c => ({
    ...c,
    width: 'auto',
  }));

  const columnObj: { [key: string]: CatalogTableRow } = Object.fromEntries(
    defaultColumns.map(c => [c.title, c]),
  );

  const newColumns = [
    columnObj.Name,
    columnObj.System,
    columnObj.Type,
    columnObj.Owner,
  ].filter(Boolean) as TableColumn<CatalogTableRow>[];

  return [...newColumns];
};
