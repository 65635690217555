import { Content, Page } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageRecentlyVisited,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageTopVisited,
  TemplateBackstageLogo,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { makeStyles } from '@material-ui/core';
import { default as Grid } from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import React from 'react';
import { synColors } from '../../theme/defaultTheme';
import { tools, useHomepageStyles } from './shared';

const { WHITE, BLACK } = synColors;

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: WHITE,
    color: BLACK,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
    '& input::placeholder': {
      opacity: 1,
    },
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const FixedHeightGrid = styled(Grid)({
  '& > div': {
    height: '100%',
  },
});

const colums = 3;

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useHomepageStyles();

  return (
    <Page themeId="home">
      <Content>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          width="100%"
        >
          <HomePageCompanyLogo
            className={container}
            logo={<TemplateBackstageLogo classes={{ svg, path }} />}
          />
          <HomePageSearchBar
            InputProps={{
              classes: {
                root: classes.searchBarInput,
                notchedOutline: classes.searchBarOutline,
              },
            }}
            placeholder="Search"
          />
          <Grid container spacing={3} size={12} alignItems="stretch">
            <FixedHeightGrid size={12 / colums}>
              <HomePageTopVisited />
            </FixedHeightGrid>
            <FixedHeightGrid size={12 / colums}>
              <HomePageRecentlyVisited />
            </FixedHeightGrid>
            <FixedHeightGrid size={12 / colums}>
              <HomePageToolkit tools={tools} />
            </FixedHeightGrid>
            <FixedHeightGrid size={12 / colums}>
              <HomePageStarredEntities />
            </FixedHeightGrid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
