import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';
import { darken, lighten } from '@mui/material/styles';

export const synColors = {
  FULL_BLACK: '#000',
  BLACK: '#181818',
  OFF_WHITE: '#f5f5f5',
  LIGHT_BLUE: '#86cec2',
  BLUE: '#3D82D1',
  WHITE: '#FFF',
};

const { BLACK, WHITE, OFF_WHITE, LIGHT_BLUE, FULL_BLACK } = synColors;

type StyleObject = Record<string, any>;

interface ComponentStyles {
  styleOverrides: StyleObject;
}

const generateComponentStyles = (styles: StyleObject): ComponentStyles => ({
  styleOverrides: { ...styles },
});

const pageTheme = {
  home: genPageTheme({
    colors: [FULL_BLACK],
    shape: shapes.wave,
    options: {
      fontColor: WHITE,
    },
  }),
  other: genPageTheme({
    colors: [LIGHT_BLUE],
    shape: shapes.wave,
    options: {
      fontColor: WHITE,
    },
  }),
};

export const defaultTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: BLACK,
      },
      secondary: {
        main: OFF_WHITE,
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: OFF_WHITE,
        paper: WHITE,
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        link: '#565a6e',
      },
      link: BLACK,
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      navigation: {
        background: BLACK,
        indicator: LIGHT_BLUE,
        color: OFF_WHITE,
        selectedColor: BLACK,
        navItem: {
          hoverBackground: lighten(BLACK, 0.1),
          linkHover: BLACK,
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
  pageTheme,
  components: {
    BackstageHeader: generateComponentStyles({
      root: {
        color: `${WHITE} !important`,
      },
      header: {
        boxShadow: 'unset',
        padding: '2rem 4rem',
      },
      subtitle: {
        display: 'none',
      },
    }),
    BackstageItemCardHeader: generateComponentStyles({
      root: {
        color: `${BLACK} !important`,
        background:
          'linear-gradient(85deg, #FFC727 0.13%, #DFEB39 98.22%) !important',
        boxShadow:
          '0px 0.88px 0.88px 0px rgba(0, 0, 0, 0.14), 0px 1.759px 0.88px 0px rgba(0, 0, 0, 0.12), 0px 0.88px 2.639px 0px rgba(0, 0, 0, 0.20)',
      },
    }),
    MuiCard: generateComponentStyles({
      root: { background: `${WHITE}` },
    }),
    MuiCardActions: generateComponentStyles({ root: { color: WHITE } }),
    BackstageSidebarItem: generateComponentStyles({
      selected: {
        color: `${OFF_WHITE} !important`,
      },
    }),
    MuiTableHead: generateComponentStyles({
      root: {
        '& > tr > th': {
          padding: '16px',
          fontWeight: 'large',
          textTransform: 'capitalize',
        },
      },
    }),
    MuiTableRow: generateComponentStyles({
      root: {
        backgroundColor: `${WHITE}`,
        borderBottom: `1px solid ${darken(WHITE, 0.1)}`,
      },
    }),
    // @ts-ignore This component is not typed but is exported by backstage
    CatalogReactUserListPicker: generateComponentStyles({
      root: {
        paddingTop: '8px',
        backgroundColor: darken(WHITE, 0.05),
        border: `1px solid ${darken(WHITE, 0.2)}`,
      },
    }),
    BackstageSelect: generateComponentStyles({
      formLabel: {
        textTransform: 'uppercase',
      },
    }),
    BackstageAutocomplete: generateComponentStyles({
      label: {
        textTransform: 'uppercase !important',
      },
    }),
    BackstageAutocompleteBase: generateComponentStyles({
      inputRoot: {
        paddingBlock: '8px !important',
      },
    }),
  },
});
