import { makeFieldSchema } from '@backstage/plugin-scaffolder-react';

export const CustomGitlabPickerFieldSchema = makeFieldSchema({
  output: z => z.string(),
  uiOptions: z =>
    z.object({
      groupInputTitle: z
        .string()
        .default('Gitlab Group')
        .optional()
        .describe('Title for the group input'),
      groupInputDescription: z
        .string()
        .default('The Gitlab group in which the repository will be created')
        .optional()
        .describe('Description for the group input'),
      projectInputTitle: z
        .string()
        .default('Gitlab Project')
        .optional()
        .describe('Title for the project input'),
      projectInputDescription: z
        .string()
        .default('The name for the GitLab repository')
        .optional()
        .describe('Description for the project input'),
      includedGroups: z
        .array(z.string())
        .optional()
        .describe(
          'List of groups to include in the group picker. Eg: synechron-code/included-group. Also includes subgroups. excludedGroups takes precedence if both are provided.',
        ),
      excludedGroups: z
        .array(z.string())
        .optional()
        .describe(
          'List of groups to exclude from the group picker. Eg: synechron-code/excluded-group. Also excludes subgroups. Takes precedence over includedGroups if both are provided.',
        ),
      requestUserCredentials: z
        .object({
          secretsKey: z
            .string()
            .default('USER_GITLAB_TOKEN')
            .describe(
              'Key used within the template secrets context to store the credential',
            ),
          additionalScopes: z
            .array(z.string())
            .optional()
            .describe('Additional GitLab scopes to request'),
        })
        .describe('Will request user credentials to auth against Gitlab'),
    }),
});

export const CustomGitlabPickerSchema = CustomGitlabPickerFieldSchema.schema;
