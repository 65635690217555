import { TableColumn } from '@backstage/core-components';
import { DocsTable, DocsTableRow } from '@backstage/plugin-techdocs';

export const genDocsTableColumns = (): TableColumn<DocsTableRow>[] => {
  const defaultColumns = Object.values(DocsTable.columns)
    .map(v => v())
    .map(col => ({ ...col, width: 'auto' }));

  return defaultColumns;
};
