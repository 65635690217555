enum LocalStorageKeys {
  SIDEBAR_PIN_STATE = 'sidebarPinState',
  HAS_REMOVED_PINNED_SETTINGS_DEFAULT = 'hasRemovedPinnedSettingsDefault',
}

export const LocalStorage = {
  getHasRemovedPinnedSettingsDefault(): boolean {
    try {
      const value = window.localStorage.getItem(
        LocalStorageKeys.HAS_REMOVED_PINNED_SETTINGS_DEFAULT,
      );
      if (value) return true;
    } catch {
      return false;
    }

    return false;
  },
  setHasRemovedPinnedSettingsDefault() {
    return window.localStorage.setItem(
      LocalStorageKeys.HAS_REMOVED_PINNED_SETTINGS_DEFAULT,
      'true',
    );
  },
  getSidebarPinState(): boolean {
    let value;
    try {
      value = JSON.parse(
        window.localStorage.getItem(LocalStorageKeys.SIDEBAR_PIN_STATE) ||
          'true',
      );
    } catch {
      return true;
    }
    return !!value;
  },
  setSidebarPinState(state: boolean) {
    return window.localStorage.setItem(
      LocalStorageKeys.SIDEBAR_PIN_STATE,
      JSON.stringify(state),
    );
  },
};
