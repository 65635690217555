import { gitlabAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import useAsync from 'react-use/esm/useAsync';
import { CustomGitlabPickerOptions } from '../../types';

export const useSetScaffolderToken = (uiOptions: CustomGitlabPickerOptions) => {
  const { setSecrets } = useTemplateSecrets();
  const gitlabAuthApi = useApi(gitlabAuthApiRef);

  const { loading, value, error } = useAsync(async () => {
    const requestUserCredentials = uiOptions?.requestUserCredentials;

    if (!requestUserCredentials) {
      // requestUserCredentials has a default value, but can be undefined when using the backstage Custom Field Explorer which doesn't auto-apply defaults
      throw new Error(
        'The Gitlab picker requires requestUserCredentials to be set in the ui:options',
      );
    }

    const result = await gitlabAuthApi.getAccessToken([
      'write_repository',
      'api',
      ...(requestUserCredentials.additionalScopes ?? []),
    ]);

    setSecrets({
      [requestUserCredentials.secretsKey]: result,
    });

    if (result) return true;
    return false;
  }, []);

  return { loading, value, error };
};
