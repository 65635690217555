import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { CustomGitlabPicker } from './components/CustomGitlabPicker';
import { CustomGitlabPickerSchema } from './schema';
import { CustomGitlabPickerValidation } from './validation';

export const CustomGitlabPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CustomGitlabPicker',
    component: CustomGitlabPicker,
    validation: CustomGitlabPickerValidation,
    schema: CustomGitlabPickerSchema,
  }),
);
