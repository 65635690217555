import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo } from 'react';
import {
  CustomGitlabPickerFormData,
  CustomGitlabPickerOptions,
} from '../types';
import { getPickerErrors, parseRepoPickerUrl } from '../validation';
import { useFetchGroups } from './hooks/useFetchGroups';

export const CustomGitlabPicker = ({
  formData,
  onChange,
  uiSchema,
}: FieldExtensionComponentProps<string>) => {
  const uiOptions = uiSchema?.['ui:options'] as CustomGitlabPickerOptions;
  const { owner, repo } = useMemo(
    () => parseRepoPickerUrl(formData),
    [formData],
  );

  const realtimeErrors = useMemo(() => getPickerErrors({ repo }), [repo]);

  const { groupFetchError, availableGroups, groupsLoading } =
    useFetchGroups(uiOptions);

  const handleChangeData = useCallback(
    (newData: Partial<CustomGitlabPickerFormData>) => {
      const searchParams = new URLSearchParams({ owner, repo });

      Object.entries(newData).forEach(([key, value]) => {
        searchParams.set(key, value);
      });

      onChange(`gitlab.com?${searchParams.toString()}`);
    },
    [onChange, owner, repo],
  );

  return (
    <FormControl margin="normal">
      {groupFetchError ? (
        <FormHelperText error>Error: {groupFetchError.message}</FormHelperText>
      ) : (
        <Autocomplete
          groupBy={option => {
            if (!availableGroups) return '';

            const lowestGroupLevelAvailable = availableGroups?.reduce(
              (acc, group) => Math.min(acc, group.title.split('/').length),
              Infinity,
            );

            const parts = option.split('/');
            return parts.slice(0, lowestGroupLevelAvailable + 1).join('/'); // Group by the first n parts of the group title, so that subgroups are grouped under their parent
          }}
          value={owner ?? null} // Default to null to avoid swapping between controlled and uncontrolled state
          onChange={(_, newValue) => {
            handleChangeData({ owner: newValue ?? '' });
          }}
          options={availableGroups?.map(group => group.title) ?? []}
          renderInput={params => (
            <TextField
              {...params}
              label={uiOptions?.groupInputTitle ?? 'Gitlab Group'}
              required
              helperText={
                uiOptions?.groupInputDescription ??
                'The Gitlab group in which the repository will be created'
              }
            />
          )}
          loading={groupsLoading}
          loadingText={
            <Stack alignItems="center" direction="row" gap={2}>
              <CircularProgress size="1.5rem" />
              <Typography variant="body1">
                Loading available groups...
              </Typography>
            </Stack>
          }
          autoSelect
        />
      )}

      <TextField
        margin="normal"
        label={uiOptions?.projectInputTitle ?? 'Project Name'}
        required
        helperText={
          uiOptions?.projectInputDescription ??
          'The name for the GitLab repository'
        }
        value={repo}
        onChange={e => handleChangeData({ repo: e.target.value })}
      />

      <FormHelperText error={!!realtimeErrors.length}>
        {realtimeErrors.join('\n')}
      </FormHelperText>
    </FormControl>
  );
};
